<template>
    <div class="image p-0 m-0">
        <img :src="img" alt="" class="w-100" />
        <PlusButton :icon="'arrow-return-left'" :theme="'info'" @click="()=>$router.go(-1)" />
    </div>
</template>

<script>
export default {
    props: {
        img: {
            type: String,
            default: null
        }
    },
}
</script>